let images = document.querySelectorAll('.image')
let wrapper = document.querySelector('.wrapper')
let about = document.querySelector('.about')
console.log(wrapper.clientHeight)

const setImageHeights = () => {
  images.forEach(el => {
    const speed = parseFloat(el.getAttribute('data-speed'))
    const offset = window.scrollY
    el.style.setProperty('transform', `translate3D(0,${offset * -speed}px,0)`)
  })
}

window.addEventListener('scroll', () => {
  setImageHeights()
  about.classList.toggle('hidden', window.scrollY > 10)
})